import { render, staticRenderFns } from "./MenuList - 副本.vue?vue&type=template&id=ef2d1ace&scoped=true&"
import script from "./MenuList - 副本.vue?vue&type=script&lang=js&"
export * from "./MenuList - 副本.vue?vue&type=script&lang=js&"
import style0 from "./MenuList - 副本.vue?vue&type=style&index=0&id=ef2d1ace&prod&lang=scss&scoped=true&"
import style1 from "./MenuList - 副本.vue?vue&type=style&index=1&id=ef2d1ace&prod&scoped=true&lang=css&"
import style2 from "./MenuList - 副本.vue?vue&type=style&index=2&id=ef2d1ace&prod&lang=scss&scoped=true&"
import style3 from "./MenuList - 副本.vue?vue&type=style&index=3&id=ef2d1ace&prod&scoped=true&lang=scss&"
import style4 from "./MenuList - 副本.vue?vue&type=style&index=4&id=ef2d1ace&prod&lang=scss&scoped=true&"
import style5 from "./MenuList - 副本.vue?vue&type=style&index=5&id=ef2d1ace&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef2d1ace",
  null
  
)

export default component.exports