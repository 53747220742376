<template>


    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true" :model="search">
                <el-form-item prop="title">
                    <el-input clearable @keydown.enter.native="searchList" v-model="searchtitle" placeholder="请输入关键字" />
                </el-form-item>
                <el-form-item>

                    <el-button type="primary" @click="searchList()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <el-button type="primary" @click="toSearch" v-if="getPurviewNew('sys:menu:add')">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加菜单
                    </el-button>

                    <!-- <el-button class="block" @click="del">批量删除</el-button> -->

                    <el-button v-show="expandKeys" v-if="getPurviewNew('sys:menu:expand')" type="primary"
                        @click="toggleRowExpansion(true)">
                        全部展开
                    </el-button>

                    <el-button v-show="!expandKeys" v-if="getPurviewNew('sys:menu:collapse')" type="danger"
                        @click="toggleRowExpansion(false)">
                        全部折叠
                    </el-button>

                </el-form-item>
            </el-form>
        </div>

        <!-- <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border ref="FanfuTable"> -->

        <el-table class="el-tableII FanfuTableBox" :data="data.list" stripe height="100%" border ref="multipleTable"
            row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">

            <!-- lazy :load="load" -->

            <el-table-column show-overflow-tooltip type="selection" width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="ID" type="index" align="center" fixed="left" />


            <!-- <el-table-column show-overflow-tooltip    prop="id" label="ID" align="left" width="80" /> -->

            <el-table-column show-overflow-tooltip label="菜单名称" align="left" width="250">
                <template slot-scope="scope">
                    <span type="expand">
                        <i class="fa fa-cube" aria-hidden="true" v-if="scope.row.type==1"></i>
                        <i class="fa fa-database" aria-hidden="true" v-if="scope.row.type==2"></i>
                        {{ scope.row.name }}</span>
                </template>
            </el-table-column>



            <el-table-column show-overflow-tooltip prop="" label="图标" align="center" width="50">
                <template slot-scope="scope">
                    <i class="layui-icon" :class="scope.row.icon" style="font-size: 30px; "></i>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="CompanyType" label="类型" align="center" width="72">
                <template slot-scope="scope">
                    <!-- 1模块 2导航 3菜单 4节点 -->
                    <el-button type="primary" round v-if="scope.row.type==1" size="mini">模块</el-button>
                    <el-button type="danger" round v-if="scope.row.type==2" size="mini">导航</el-button>
                    <el-button type="warning" round v-if="scope.row.type==3" size="mini">菜单</el-button>
                    <el-button type="" round v-if="scope.row.type==4" size="mini">节点</el-button>
                </template>
            </el-table-column>

            <!-- <el-table-column show-overflow-tooltip    prop="url" label="菜单地址" align="center" width="200" /> -->
            <el-table-column show-overflow-tooltip prop="route" label="菜单地址" align="center" width="300" />


            <el-table-column show-overflow-tooltip prop="permission" label="权限标识" align="center" width="200" />


            <el-table-column show-overflow-tooltip prop="state" label="状态" align="left" width="90">
                <template slot-scope="scope">
                    <!-- 1显示 2不显示 -->
                    <el-switch v-model="scope.row.status" :active-value="1" active-color="#5BD995" active-text="显示"
                        :inactive-value="2" inactive-color="#D9D9D9" inactive-text="隐藏" class="switchSelfBox"
                        style="pointer-events: painted;" @change="switchStatus(scope.row)">
                    </el-switch>
                </template>
            </el-table-column>



            <!-- 是否公共：1是 2否 -->
            <!-- <el-table-column show-overflow-tooltip prop="state" label="是否公共" align="left" width="80">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.is_public" :active-value="1" active-color="#5BD995" active-text="是"
                        :inactive-value="2" inactive-color="#D9D9D9" inactive-text="否" class="switchSelfBox">
                    </el-switch>
                </template>
            </el-table-column> -->


            <el-table-column show-overflow-tooltip prop="sort" label="排序" align="center" min-width="70" />


            <!-- <el-table-column show-overflow-tooltip    prop="create_name" label="创建人" align="left" />
            <el-table-column show-overflow-tooltip    prop="create_time" label="创建时间" align="left" />
            <el-table-column show-overflow-tooltip    prop="update_time" label="更新时间" align="left" /> -->


            <el-table-column show-overflow-tooltip label="操作" align="center"
                :width="autoTableWidth(['sys:menu:addz','sys:menu:edit','sys:menu:dall'],75)">
                <template slot-scope="scope">
                    <div class="operate">
                        <el-button @click="toSearchChild(scope.row)" v-if="getPurviewNew('sys:menu:addz')">
                            添加
                        </el-button>
                        <el-button type="primary" @click="edit(scope.row)" v-if="getPurviewNew('sys:menu:edit')">
                            编辑
                        </el-button>
                        <el-button type="danger" @click="del(scope.row)" v-if="getPurviewNew('sys:menu:dall')">
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>

        </el-table>
        <!-- </Fanfu-Table> -->

        <!--  <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" /> -->


        <Search ref="searchRef" v-model="search" @refresh="getList" />


    </div>
</template>

<script>
    import Search from './components/MenuListSearch'

    import resizeDetector from 'element-resize-detector'

    export default {
        components: {
            Search,
        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },

                loading: false,

                select: [],

                data: {
                    list: [],
                    Alllist: [],
                    page: 1,
                    size: 15,
                    total: 0
                },

                searchtitle: "",

                expandKeys: true,


                // add_menu_btn:
                // JSON.parse(localStorage.getItem('user')).menu

            }
        },

        mounted() {
            this.modifyLayout();
        },

        created() {
            this.modifyLayout();

            this.getList();
        },
        watch: {
            searchtitle(newValue, oldValue) {
                if (oldValue.length > 0 && newValue.length == 0) {

                    this.searchList()

                }

            }
        },
        methods: {

            toggleRowExpansion(val) {
                this.expandKeys = !this.expandKeys
                this.formatTree(this.data.list, 0)
            },

            formatTree(tree, val, val1) {
                for (const node of tree) {

                    if (this.expandKeys) {
                        this.$refs.multipleTable.toggleRowExpansion(node, false);
                    } else {
                        this.$refs.multipleTable.toggleRowExpansion(node, true);
                    }

                    if (node.children && node.children.length) {
                        this.formatTree(node.children, val + 1)
                    }
                }
            },

            expandChange(row, expandRow) {
                console.log(row, expandRow);
            },



            // AllTotal() {
            //     let a = list.length;
            //     for (let i = 0; i < list.length; i++) {
            //         var dic = list[i];
            //         if (dic.hasOwnProperty("children")) {
            //             a = a + dic.children.length
            //         }
            //     }
            //     return a;
            // },

            // getList(size) {
            //     if (size) {
            //         this.data.page = 1
            //         this.data.size = size
            //     }
            //     this.loading = true
            //     this.select = []
            //     this.$nextTick(() => {
            //         const start = this.data.size * (this.data.page - 1)
            //         const end = start + this.data.size
            //         this.data.total = list.length
            //         const data = []
            //         for (let i = start; i < end; i++) {
            //             if (list[i]) {
            //                 data.push(list[i])
            //             }
            //         }
            //         this.data.list = data
            //         setTimeout(() => {
            //             this.loading = false
            //         }, 1000)
            //         setTimeout(() => {
            //             this.modifyLayout();
            //         }, 1100)
            //     })
            // },

            getList() {

                this.$http.get('/api/menu/tree', {
                    name: this.searchtitle,
                    // page_size: this.data.size,
                    // page: this.data.page

                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {

                        this.data.list = res.data

                        // this.data.list = res.data.map(x => {

                        //     if (x.children && x.children.length) {
                        //         x.hasChildren = true

                        //     }

                        //     // x.children = []
                        //     delete x.children
                        //     return x
                        // })

                        // this.data.Alllist = res.data



                        // var a = [...res.data]

                        // this.formatTree1(a, 0)

                        // setTimeout(() => {

                        //     console.log("~~~~~~~~~~~~~~~~~~~~~~~~~", a);
                        //     this.data.Alllist = a

                        // }, 3000)



                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },



            formatTree1(tree, val, val1) {
                for (const node of tree) {
                    if (node.children && node.children.length) {
                        node.hasChildren = true
                        this.formatTree1(node.children, val + 1)
                    } else {
                        node.hasChildren = false
                    }
                }
            },



            // 展开
            load(tree, treeNode, resolve) {


                // 层级关系备份
                const idCopy = JSON.parse(JSON.stringify(tree.idList))

                // 查找下一层数据
                let resolveArr = this.tableDataCopy

                let id
                // eslint-disable-next-line
                while (id = tree.idList.shift()) {


                    const tarItem = resolveArr.find(item => item.objId === id)
                    resolveArr = tarItem.list
                }

                // 处理下一层数据的属性
                resolveArr = JSON.parse(JSON.stringify(resolveArr))
                resolveArr.forEach(item => {


                    item.hasChildren = item.list && item.list.length > 0
                    item.list = null
                    // 此处需要深拷贝，以防各个item的idList混乱
                    item.idList = JSON.parse(JSON.stringify(idCopy))
                    item.idList.push(item.objId)
                })

                // 渲染子节点
                resolve(resolveArr)
            },




            searchList() {
                this.getList()
            },



            view(row) {
                console.log(row)
            },

            edit(row) {
                // console.log(row)
                this.$refs.searchRef.edit(row)
            },



            switchStatus(row) {

                // console.log(row);

                // return

                let a = row.status == 1 ? 2 : 1

                this.$http.put('/api//menu/setStatus/' + row.id, {
                    status: row.status
                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                    if (res.code != 200) { //请求错误
                        row.status = a
                        this.$message.error(res.msg);
                    }
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        // this.close()
                    }
                }).catch((err) => {
                    console.log(err)
                });


            },



            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';

                            // setTimeout(() => {
                            //     done();
                            //     setTimeout(() => {
                            //         instance.confirmButtonLoading = false;
                            //     }, 300);
                            // }, 3000);

                            this.$http.del('/api/menu/' + row.id).then((res) => {
                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                                if (res.code == 200) {
                                    this.getList()
                                }
                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);
                                }

                                // setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                                // }, 3000);


                            }).catch((err) => {
                                console.log(err)
                            });


                        } else {
                            done();
                        }
                    }

                }).then(action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                });
            },


            toSearch() {
                this.$refs.searchRef.open()
            },
            toSearchChild(row) {
                this.$refs.searchRef.openChild(row)
            },


            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    var a = 141 + 60 - 35 + height - 60;
                    if ($(".level_indexI .FanfuTableBox")[0]) {

                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');

                    }


                    var b = 141 + 60 + 33 + height - 60;
                    if ($(".level_indexI .el-table")[0]) {

                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');

                    }


                });

            },


            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);

                this.data.page = val

                this.getList()
            },



        },


    }
</script>





<style lang="scss" scoped>
    .el-button--success {
        color: #ffffff;
        background-color: #5BD995;
        border-color: #5BD995;
    }
</style>


<style scoped>
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  } */
    /*  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini { */

    /*  .level_indexI .el-table {
    border: 1px red dotted;
    box-sizing: border-box;
  } */
</style>



























<template>


    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true" :model="search">
                <el-form-item prop="title">
                    <el-input clearable @keydown.enter.native="searchList" v-model="searchtitle" placeholder="请输入关键字" />
                </el-form-item>
                <el-form-item>

                    <el-button type="primary" @click="searchList()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <el-button type="primary" @click="toSearch" v-if="getPurviewNew('sys:menu:add')">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加菜单
                    </el-button>

                    <!-- <el-button class="block" @click="del">批量删除</el-button> -->

                    <el-button v-show="expandKeys" v-if="getPurviewNew('sys:menu:expand')" type="primary"
                        @click="toggleRowExpansion(true)">
                        全部展开
                    </el-button>

                    <el-button v-show="!expandKeys" v-if="getPurviewNew('sys:menu:collapse')" type="danger"
                        @click="toggleRowExpansion(false)">
                        全部折叠
                    </el-button>

                </el-form-item>
            </el-form>
        </div>

        <!-- <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border ref="FanfuTable"> -->


        <div class="el-tableII FanfuTableBox">


            <div class="headView">
                <span>名称</span>
                <span>图标</span>
                <span>类型</span>
                <span>状态</span>
                <span>地址</span>
                <span>权限标识</span>
                <span>排序</span>
                <span>操作</span>
            </div>


            <el-tree :data="data.list" stripe border ref="multipleTable" row-key="id"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}" :props="defaultProps"
                @node-click="handleNodeClick" node-key="id">

                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}</span>

                    <span class="custom_table_cell">

                        <!-- <el-button type="text" size="mini" @click="() => append(data)">
                        Append
                    </el-button> -->

                        <i class="layui-icon" :class="data.icon" style="font-size: 20px; "></i>



                        <!-- 1模块 2导航 3菜单 4节点 -->
                        <template>
                            <el-button type="primary" round v-if="data.type==1" size="mini">模块</el-button>
                            <el-button type="danger" round v-if="data.type==2" size="mini">导航</el-button>
                            <el-button type="warning" round v-if="data.type==3" size="mini">菜单</el-button>
                            <el-button type="" round v-if="data.type==4" size="mini">节点</el-button>
                        </template>


                        <el-switch v-model="data.status" :active-value="1" active-color="#5BD995" active-text="显示"
                            :inactive-value="2" inactive-color="#D9D9D9" inactive-text="隐藏" class="switchSelfBox"
                            style="pointer-events: painted;" @click.native.stop="switchStatus(data)">
                            <!-- @change.native.stop="switchStatus(data)" -->
                        </el-switch>

                        <b>{{data.route}}</b>
                        <b>{{data.permission}}</b>

                        <b>{{data.sort}}</b>

                        <div class="operate" :style="{'width':operateWidth+'px'}">
                            <el-button @click.native.stop="toSearchChild(data)" v-if="getPurviewNew('sys:menu:addz')">
                                添加
                            </el-button>
                            <el-button type="primary" @click.native.stop="edit(data)"
                                v-if="getPurviewNew('sys:menu:edit')">
                                编辑
                            </el-button>
                            <el-button type="danger" @click.native.stop="del(data)"
                                v-if="getPurviewNew('sys:menu:dall')">
                                删除
                            </el-button>
                        </div>




                        <!-- <el-table-column show-overflow-tooltip label="操作" align="center"
                        :width="autoTableWidth(['sys:menu:addz','sys:menu:edit','sys:menu:dall'],75)">
                        <template slot-scope="scope">
                            <div class="operate">
                                <el-button @click="toSearchChild(scope.row)" v-if="getPurviewNew('sys:menu:addz')">
                                    添加
                                </el-button>
                                <el-button type="primary" @click="edit(scope.row)" v-if="getPurviewNew('sys:menu:edit')">
                                    编辑
                                </el-button>
                                <el-button type="danger" @click="del(scope.row)" v-if="getPurviewNew('sys:menu:dall')">
                                    删除
                                </el-button>
                            </div>
                        </template>
                    </el-table-column> -->


                    </span>


                </span>


            </el-tree>

        </div>


        <Search ref="searchRef" v-model="search" @refresh="getList" />


    </div>
</template>

<script>
    import Search from './components/MenuListSearch'

    import resizeDetector from 'element-resize-detector'

    export default {
        components: {
            Search,
        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },

                loading: false,

                select: [],

                data: {
                    list: [],
                    Alllist: [],
                    page: 1,
                    size: 15,
                    total: 0
                },


                ALLOpen: [],

                searchtitle: "",

                expandKeys: true,


                defaultProps: {
                    children: 'children',
                    label: 'name'
                }

                // add_menu_btn:
                // JSON.parse(localStorage.getItem('user')).menu

            }
        },

        mounted() {
            this.modifyLayout();
        },

        created() {
            this.modifyLayout();

            this.getList();
        },
        watch: {
            searchtitle(newValue, oldValue) {
                if (oldValue.length > 0 && newValue.length == 0) {

                    this.searchList()

                }

            }
        },
        computed: {
            operateWidth() {
                var a = this.autoTableWidth(['sys:menu:addz', 'sys:menu:edit', 'sys:menu:dall'], 75)
                return a
            }
        },

        methods: {

            handleNodeClick(data) {
                console.log(data);
            },


            toggleRowExpansion(val) {
                this.expandKeys = !this.expandKeys

                const tree = this.$refs.multipleTable.store.nodesMap

                for (let key in tree) {
                    tree[key].expanded = !this.expandKeys
                }

            },

            expandChange(row, expandRow) {
                console.log(row, expandRow);
            },



            // AllTotal() {
            //     let a = list.length;
            //     for (let i = 0; i < list.length; i++) {
            //         var dic = list[i];
            //         if (dic.hasOwnProperty("children")) {
            //             a = a + dic.children.length
            //         }
            //     }
            //     return a;
            // },

            // getList(size) {
            //     if (size) {
            //         this.data.page = 1
            //         this.data.size = size
            //     }
            //     this.loading = true
            //     this.select = []
            //     this.$nextTick(() => {
            //         const start = this.data.size * (this.data.page - 1)
            //         const end = start + this.data.size
            //         this.data.total = list.length
            //         const data = []
            //         for (let i = start; i < end; i++) {
            //             if (list[i]) {
            //                 data.push(list[i])
            //             }
            //         }
            //         this.data.list = data
            //         setTimeout(() => {
            //             this.loading = false
            //         }, 1000)
            //         setTimeout(() => {
            //             this.modifyLayout();
            //         }, 1100)
            //     })
            // },

            getList() {

                this.$http.get('/api/menu/tree', {
                    name: this.searchtitle,
                    // page_size: this.data.size,
                    // page: this.data.page

                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {

                        this.data.list = res.data

                        // this.data.list = res.data.map(x => {

                        //     if (x.children && x.children.length) {
                        //         x.hasChildren = true

                        //     }

                        //     // x.children = []
                        //     delete x.children
                        //     return x
                        // })

                        // this.data.Alllist = res.data



                        // var a = [...res.data]

                        // this.formatTree1(a, 0)

                        // setTimeout(() => {

                        //     console.log("~~~~~~~~~~~~~~~~~~~~~~~~~", a);
                        //     this.data.Alllist = a

                        // }, 3000)



                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },



            formatTree1(tree, val, val1) {
                for (const node of tree) {
                    if (node.children && node.children.length) {
                        node.hasChildren = true
                        this.formatTree1(node.children, val + 1)
                    } else {
                        node.hasChildren = false
                    }
                }
            },



            // 展开
            load(tree, treeNode, resolve) {


                // 层级关系备份
                const idCopy = JSON.parse(JSON.stringify(tree.idList))

                // 查找下一层数据
                let resolveArr = this.tableDataCopy

                let id
                // eslint-disable-next-line
                while (id = tree.idList.shift()) {


                    const tarItem = resolveArr.find(item => item.objId === id)
                    resolveArr = tarItem.list
                }

                // 处理下一层数据的属性
                resolveArr = JSON.parse(JSON.stringify(resolveArr))
                resolveArr.forEach(item => {


                    item.hasChildren = item.list && item.list.length > 0
                    item.list = null
                    // 此处需要深拷贝，以防各个item的idList混乱
                    item.idList = JSON.parse(JSON.stringify(idCopy))
                    item.idList.push(item.objId)
                })

                // 渲染子节点
                resolve(resolveArr)
            },




            searchList() {
                this.getList()
            },



            view(row) {
                console.log(row)
            },

            edit(row) {
                // console.log(row)
                this.$refs.searchRef.edit(row)
            },



            switchStatus(row) {

                // console.log(row);

                // return

                let a = row.status == 1 ? 2 : 1

                this.$http.put('/api//menu/setStatus/' + row.id, {
                    status: row.status
                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                    if (res.code != 200) { //请求错误
                        row.status = a
                        this.$message.error(res.msg);
                    }
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        // this.close()
                    }
                }).catch((err) => {
                    console.log(err)
                });


            },



            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';

                            // setTimeout(() => {
                            //     done();
                            //     setTimeout(() => {
                            //         instance.confirmButtonLoading = false;
                            //     }, 300);
                            // }, 3000);

                            this.$http.del('/api/menu/' + row.id).then((res) => {
                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                                if (res.code == 200) {
                                    this.getList()
                                }
                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);
                                }

                                // setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                                // }, 3000);


                            }).catch((err) => {
                                console.log(err)
                            });


                        } else {
                            done();
                        }
                    }

                }).then(action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                });
            },


            toSearch() {
                this.$refs.searchRef.open()
            },
            toSearchChild(row) {
                this.$refs.searchRef.openChild(row)
            },


            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    // // var a = 141 + 60 - 35 + height - 60 + 70;
                    // var a = 141 + 60 - 35 + height - 60 + 70 + 30;
                    // if ($(".level_indexI .FanfuTableBox")[0]) {

                    //     $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                    //         'px) !important');

                    //     console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~1111111111");

                    // }

                    var a = 141 + 60 - 35 + height - 60 + 70;
                    if ($(".FanfuTableBox")[0]) {

                        $(".FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');

                        console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~1111111111");

                    }



                    // var b = 141 + 60 + 33 + height - 60 - 100;
                    // if ($(".level_indexI .el-tree")[0]) {

                    //     $(".level_indexI .el-tree")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                    //         'px) !important');

                    //     console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~22222222222222");

                    // }


                });

            },


            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);

                this.data.page = val

                this.getList()
            },



        },


    }
</script>





<style lang="scss" scoped>
    .el-button--success {
        color: #ffffff;
        background-color: #5BD995;
        border-color: #5BD995;
    }
</style>


<style scoped lang="scss">
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue solid;
    box-sizing: border-box;
  } */
    /*  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini { */

    /*  .level_indexI .el-table {
    border: 1px red solid;
    box-sizing: border-box;
  } */




    .headView {
        // border: 1px #dfdfdf solid;
        border-bottom: 1px #dfdfdf solid;
        box-sizing: border-box;
        height: 36px;
        z-index: 99 !important;

        position: sticky;
        top: 0;
        left: 0;

        // display: block;

        min-width: 1300px;
        background-color: #f5f5f5 !important;

        span {
            line-height: 36px;
            font-size: 14px;
            display: inline-block;
            // border-left: 1px #dfdfdf solid;
            // box-sizing: border-box;
            text-align: center;
            position: relative;
        }

        span::after {
            content: "";
            border-right: 1px #dfdfdf solid;
            box-sizing: border-box;
            width: 0px;
            height: calc(100vh - 222px);
            position: absolute;
            right: 0;
        }

        span:nth-of-type(1) {
            width: 290px;
            border: none;
        }

        span:nth-of-type(2) {
            width: 45px;
        }

        span:nth-of-type(3) {
            width: 67px;
        }

        span:nth-of-type(4) {
            width: 70px;
        }

        span:nth-of-type(5) {
            width: 300px;
        }

        span:nth-of-type(6) {
            width: 210px;
        }

        span:nth-of-type(7) {
            width: 60px;
        }

        span:nth-of-type(8) {
            width: 258px;
        }
    }








    // .el-tableII
    .FanfuTableBox {
        border: 1px #dfdfdf solid;
        box-sizing: border-box;

        overflow-y: scroll;
        overflow-x: scroll;
        padding: 0px 0px 0px 0px;

        position: relative;



        .layui-icon {
            width: 20px;
            height: 20px;
            display: inline-block;
            // border: 1px red solid;
            box-sizing: border-box;

            // margin-top: 5px;
            position: relative;
            top: 5px;
            left: -6px;
        }



        b:nth-of-type(1) {
            width: 300px;
            height: 20px;
            display: inline-block;
            // border: 1px red solid;
            box-sizing: border-box;
        }

        b:nth-of-type(2) {
            width: 200px;
            height: 20px;
            display: inline-block;
            // border: 1px red solid;
            box-sizing: border-box;
        }

        b:nth-of-type(3) {
            width: 50px;
            // height: 20px;
            // display: inline-block;
            // border: 1px red solid;
            box-sizing: border-box;
        }

    }

    /deep/.el-tree-node__content {
        // border: 1px green solid;
        border-bottom: 1px #dfdfdf solid;
        box-sizing: border-box;

        // width: 100%;
        height: 100%;
        // padding: 5px 0 !important;

        overflow: visible;
        // width: auto;
        // float: left;
        // display: inline-block;
    }


    // el-tree-node is-expanded is-current is-focusable

    /deep/.el-tree-node {
        // border: 1px green solid;
        box-sizing: border-box;
        // padding: 10px 0;

        // width: auto;
        // display: inline-block;

        overflow: visible;

        position: relative;


        min-width: 1300px;

        // min-width: 1000px;
        // width: 100%;
        // display: block;

    }

    .custom-tree-node {
        // border: 1px red solid;
        box-sizing: border-box;

        // padding: 5px;

        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        // align-items: center;

        span,
        button {
            font-size: 12px;
        }

        span:nth-of-type(1) {
            // border: 2px red solid;
            box-sizing: border-box;

            line-height: 36px;
            margin-right: 10px;
            // display: inline;
            width: 200px;

            display: inline-block;
        }
    }

    // /deep/ 
    .custom_table_cell {

        // border: 1px blue solid;
        box-sizing: border-box;

        // display: inline-block;

        position: absolute;
        top: 3px;
        left: 300px;
        // height: 100%;

        // float: right;

        // display: inline-table;
        // flex-direction: row;
        // justify-content: space-between;
        // align-items: center;

        * {
            margin-left: 10px;
        }

        button {
            font-size: 12px;
            height: auto;
        }




        .operate {
            // border: 1px purple solid;
            box-sizing: border-box;
            display: inline-block;

            // button {
            //     font-size: 12px;
            //     height: auto;
            // }
        }

        b {
            // border: 1px red solid;
            // box-sizing: border-box;
            display: inline-block;
            font-size: 12px;
        }

    }
</style>


















<template>


    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true" :model="search">
                <el-form-item prop="title">
                    <el-input clearable @keydown.enter.native="searchList" v-model="searchtitle" placeholder="请输入关键字" />
                </el-form-item>
                <el-form-item>

                    <el-button type="primary" @click="searchList()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <el-button type="primary" @click="toSearch" v-if="getPurviewNew('sys:menu:add')">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加菜单
                    </el-button>

                    <!-- <el-button class="block" @click="del">批量删除</el-button> -->

                    <el-button v-show="expandKeys" v-if="getPurviewNew('sys:menu:expand')" type="primary"
                        @click="toggleRowExpansion(true)">
                        全部展开
                    </el-button>

                    <el-button v-show="!expandKeys" v-if="getPurviewNew('sys:menu:collapse')" type="danger"
                        @click="toggleRowExpansion(false)">
                        全部折叠
                    </el-button>

                </el-form-item>
            </el-form>
        </div>

        <!-- <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border ref="FanfuTable"> -->


        <div class="el-tableII FanfuTableBox">


            <div class="headView">
                <span>名称</span>
                <span>图标</span>
                <span>类型</span>
                <span>状态</span>
                <span>地址</span>
                <span>权限标识</span>
                <span>排序</span>
                <span>操作</span>
            </div>


            <el-tree :data="data.list" stripe border ref="multipleTable" row-key="id"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}" :props="defaultProps"
                @node-click="handleNodeClick" node-key="id">

                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}</span>

                    <span class="custom_table_cell">

                        <!-- <el-button type="text" size="mini" @click="() => append(data)">
                        Append
                    </el-button> -->

                        <i class="layui-icon" :class="data.icon" style="font-size: 20px; "></i>



                        <!-- 1模块 2导航 3菜单 4节点 -->
                        <template>
                            <el-button type="primary" round v-if="data.type==1" size="mini">模块</el-button>
                            <el-button type="danger" round v-if="data.type==2" size="mini">导航</el-button>
                            <el-button type="warning" round v-if="data.type==3" size="mini">菜单</el-button>
                            <el-button type="" round v-if="data.type==4" size="mini">节点</el-button>
                        </template>


                        <el-switch v-model="data.status" :active-value="1" active-color="#5BD995" active-text="显示"
                            :inactive-value="2" inactive-color="#D9D9D9" inactive-text="隐藏" class="switchSelfBox"
                            style="pointer-events: painted;" @click.native.stop="switchStatus(data)">
                            <!-- @change.native.stop="switchStatus(data)" -->
                        </el-switch>

                        <b>{{data.route}}</b>
                        <b>{{data.permission}}</b>

                        <b>{{data.sort}}</b>

                        <div class="operate" :style="{'width':operateWidth+'px'}">
                            <el-button @click.native.stop="toSearchChild(data)" v-if="getPurviewNew('sys:menu:addz')">
                                添加
                            </el-button>
                            <el-button type="primary" @click.native.stop="edit(data)"
                                v-if="getPurviewNew('sys:menu:edit')">
                                编辑
                            </el-button>
                            <el-button type="danger" @click.native.stop="del(data)"
                                v-if="getPurviewNew('sys:menu:dall')">
                                删除
                            </el-button>
                        </div>




                        <!-- <el-table-column show-overflow-tooltip label="操作" align="center"
                        :width="autoTableWidth(['sys:menu:addz','sys:menu:edit','sys:menu:dall'],75)">
                        <template slot-scope="scope">
                            <div class="operate">
                                <el-button @click="toSearchChild(scope.row)" v-if="getPurviewNew('sys:menu:addz')">
                                    添加
                                </el-button>
                                <el-button type="primary" @click="edit(scope.row)" v-if="getPurviewNew('sys:menu:edit')">
                                    编辑
                                </el-button>
                                <el-button type="danger" @click="del(scope.row)" v-if="getPurviewNew('sys:menu:dall')">
                                    删除
                                </el-button>
                            </div>
                        </template>
                    </el-table-column> -->


                    </span>


                </span>


            </el-tree>

        </div>


        <Search ref="searchRef" v-model="search" @refresh="getList" />


    </div>
</template>

<script>
    import Search from './components/MenuListSearch'

    import resizeDetector from 'element-resize-detector'

    export default {
        components: {
            Search,
        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },

                loading: false,

                select: [],

                data: {
                    list: [],
                    Alllist: [],
                    page: 1,
                    size: 15,
                    total: 0
                },


                ALLOpen: [],

                searchtitle: "",

                expandKeys: true,


                defaultProps: {
                    children: 'children',
                    label: 'name'
                }

                // add_menu_btn:
                // JSON.parse(localStorage.getItem('user')).menu

            }
        },

        mounted() {
            this.modifyLayout();
        },

        created() {
            this.modifyLayout();

            this.getList();
        },
        watch: {
            searchtitle(newValue, oldValue) {
                if (oldValue.length > 0 && newValue.length == 0) {

                    this.searchList()

                }

            }
        },
        computed: {
            operateWidth() {
                var a = this.autoTableWidth(['sys:menu:addz', 'sys:menu:edit', 'sys:menu:dall'], 75)
                return a
            }
        },

        methods: {

            handleNodeClick(data) {
                console.log(data);
            },


            toggleRowExpansion(val) {
                this.expandKeys = !this.expandKeys

                const tree = this.$refs.multipleTable.store.nodesMap

                for (let key in tree) {
                    tree[key].expanded = !this.expandKeys
                }

            },

            expandChange(row, expandRow) {
                console.log(row, expandRow);
            },



            // AllTotal() {
            //     let a = list.length;
            //     for (let i = 0; i < list.length; i++) {
            //         var dic = list[i];
            //         if (dic.hasOwnProperty("children")) {
            //             a = a + dic.children.length
            //         }
            //     }
            //     return a;
            // },

            // getList(size) {
            //     if (size) {
            //         this.data.page = 1
            //         this.data.size = size
            //     }
            //     this.loading = true
            //     this.select = []
            //     this.$nextTick(() => {
            //         const start = this.data.size * (this.data.page - 1)
            //         const end = start + this.data.size
            //         this.data.total = list.length
            //         const data = []
            //         for (let i = start; i < end; i++) {
            //             if (list[i]) {
            //                 data.push(list[i])
            //             }
            //         }
            //         this.data.list = data
            //         setTimeout(() => {
            //             this.loading = false
            //         }, 1000)
            //         setTimeout(() => {
            //             this.modifyLayout();
            //         }, 1100)
            //     })
            // },

            getList() {

                this.$http.get('/api/menu/tree', {
                    name: this.searchtitle,
                    // page_size: this.data.size,
                    // page: this.data.page

                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {

                        this.data.list = res.data

                        // this.data.list = res.data.map(x => {

                        //     if (x.children && x.children.length) {
                        //         x.hasChildren = true

                        //     }

                        //     // x.children = []
                        //     delete x.children
                        //     return x
                        // })

                        // this.data.Alllist = res.data



                        // var a = [...res.data]

                        // this.formatTree1(a, 0)

                        // setTimeout(() => {

                        //     console.log("~~~~~~~~~~~~~~~~~~~~~~~~~", a);
                        //     this.data.Alllist = a

                        // }, 3000)



                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },



            formatTree1(tree, val, val1) {
                for (const node of tree) {
                    if (node.children && node.children.length) {
                        node.hasChildren = true
                        this.formatTree1(node.children, val + 1)
                    } else {
                        node.hasChildren = false
                    }
                }
            },



            // 展开
            load(tree, treeNode, resolve) {


                // 层级关系备份
                const idCopy = JSON.parse(JSON.stringify(tree.idList))

                // 查找下一层数据
                let resolveArr = this.tableDataCopy

                let id
                // eslint-disable-next-line
                while (id = tree.idList.shift()) {


                    const tarItem = resolveArr.find(item => item.objId === id)
                    resolveArr = tarItem.list
                }

                // 处理下一层数据的属性
                resolveArr = JSON.parse(JSON.stringify(resolveArr))
                resolveArr.forEach(item => {


                    item.hasChildren = item.list && item.list.length > 0
                    item.list = null
                    // 此处需要深拷贝，以防各个item的idList混乱
                    item.idList = JSON.parse(JSON.stringify(idCopy))
                    item.idList.push(item.objId)
                })

                // 渲染子节点
                resolve(resolveArr)
            },




            searchList() {
                this.getList()
            },



            view(row) {
                console.log(row)
            },

            edit(row) {
                // console.log(row)
                this.$refs.searchRef.edit(row)
            },



            switchStatus(row) {

                // console.log(row);

                // return

                let a = row.status == 1 ? 2 : 1

                this.$http.put('/api//menu/setStatus/' + row.id, {
                    status: row.status
                }).then((res) => {
                    // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                    if (res.code != 200) { //请求错误
                        row.status = a
                        this.$message.error(res.msg);
                    }
                    if (res.code == 200) {
                        this.$message.success(res.msg);
                        // this.close()
                    }
                }).catch((err) => {
                    console.log(err)
                });


            },



            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';

                            // setTimeout(() => {
                            //     done();
                            //     setTimeout(() => {
                            //         instance.confirmButtonLoading = false;
                            //     }, 300);
                            // }, 3000);

                            this.$http.del('/api/menu/' + row.id).then((res) => {
                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                                if (res.code == 200) {
                                    this.getList()
                                }
                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);
                                }

                                // setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                                // }, 3000);


                            }).catch((err) => {
                                console.log(err)
                            });


                        } else {
                            done();
                        }
                    }

                }).then(action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                });
            },


            toSearch() {
                this.$refs.searchRef.open()
            },
            toSearchChild(row) {
                this.$refs.searchRef.openChild(row)
            },


            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    // // var a = 141 + 60 - 35 + height - 60 + 70;
                    // var a = 141 + 60 - 35 + height - 60 + 70 + 30;
                    // if ($(".level_indexI .FanfuTableBox")[0]) {

                    //     $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                    //         'px) !important');

                    //     console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~1111111111");

                    // }

                    var a = 141 + 60 - 35 + height - 60 + 70;
                    if ($(".FanfuTableBox")[0]) {

                        $(".FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');

                        console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~1111111111");

                    }



                    // var b = 141 + 60 + 33 + height - 60 - 100;
                    // if ($(".level_indexI .el-tree")[0]) {

                    //     $(".level_indexI .el-tree")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                    //         'px) !important');

                    //     console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~22222222222222");

                    // }


                });

            },


            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);

                this.data.page = val

                this.getList()
            },



        },


    }
</script>





<style lang="scss" scoped>
    .el-button--success {
        color: #ffffff;
        background-color: #5BD995;
        border-color: #5BD995;
    }
</style>


<style scoped lang="scss">
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue solid;
    box-sizing: border-box;
  } */
    /*  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini { */

    /* .level_indexI .el-table {
    border: 1px red solid;
    box-sizing: border-box;
  } */




    .headView {
        // border: 1px #dfdfdf solid;
        border-bottom: 1px #dfdfdf solid;
        box-sizing: border-box;
        height: 36px;
        z-index: 999 !important;

        position: sticky;
        top: 0;
        left: 0;

        // display: block;

        min-width: 1300px;
        background-color: #f5f5f5 !important;

        span {
            line-height: 36px;
            font-size: 14px;
            display: inline-block;
            // border-left: 1px #dfdfdf solid;
            // box-sizing: border-box;
            text-align: center;
            position: relative;
        }

        span::after {
            content: "";
            border-right: 1px #dfdfdf solid;
            box-sizing: border-box;
            width: 0px;
            height: calc(100vh - 222px);
            position: absolute;
            right: 0;
        }

        span:nth-of-type(1) {
            width: 290px;
            border: none;
        }

        span:nth-of-type(2) {
            width: 45px;
        }

        span:nth-of-type(3) {
            width: 67px;
        }

        span:nth-of-type(4) {
            width: 70px;
        }

        span:nth-of-type(5) {
            width: 300px;
        }

        span:nth-of-type(6) {
            width: 210px;
        }

        span:nth-of-type(7) {
            width: 60px;
        }

        span:nth-of-type(8) {
            width: 258px;

            // border: 1px purple solid;
            // box-sizing: border-box;


        }
    }








    // .el-tableII
    .FanfuTableBox {
        border: 1px #dfdfdf solid;
        box-sizing: border-box;

        overflow-y: scroll;
        overflow-x: scroll;
        padding: 0px 0px 0px 0px;

        position: relative;



        .layui-icon {
            width: 20px;
            height: 20px;
            display: inline-block;
            // border: 1px red solid;
            box-sizing: border-box;

            // margin-top: 5px;
            position: relative;
            top: 5px;
            left: -6px;
        }



        b:nth-of-type(1) {
            width: 300px;
            height: 20px;
            display: inline-block;
            // border: 1px red solid;
            box-sizing: border-box;
        }

        b:nth-of-type(2) {
            width: 200px;
            height: 20px;
            display: inline-block;
            // border: 1px red solid;
            box-sizing: border-box;
        }

        b:nth-of-type(3) {
            width: 50px;
            // height: 20px;
            // display: inline-block;
            // border: 1px red solid;
            box-sizing: border-box;
        }

    }

    /deep/.el-tree-node__content {
        // border: 1px green solid;
        border-bottom: 1px #dfdfdf solid;
        box-sizing: border-box;

        // width: 100%;
        height: 100%;
        // padding: 5px 0 !important;

        overflow: visible;
        // width: auto;
        // float: left;
        // display: inline-block;
    }


    // el-tree-node is-expanded is-current is-focusable

    /deep/.el-tree-node {
        // border: 1px green solid;
        box-sizing: border-box;
        // padding: 10px 0;

        // width: auto;
        // display: inline-block;

        overflow: visible;

        position: relative;

        min-width: 1300px;

        // min-width: 1000px;
        // display: block;

        // position: absolute;

        // width: calc(100% - 100px) !important;
    }

    /deep/.el-tree-node__content {
        // border: 1px green solid;
        // box-sizing: border-box;
        // position: relative;

    }


    .custom-tree-node {
        // border: 1px red solid;
        box-sizing: border-box;

        // padding: 5px;

        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        // align-items: center;

        span,
        button {
            font-size: 12px;
        }

        span:nth-of-type(1) {
            // border: 2px red solid;
            box-sizing: border-box;

            line-height: 36px;
            margin-right: 10px;
            // display: inline;
            width: 200px;

            display: inline-block;
        }
    }

    // /deep/ 
    .custom_table_cell {

        // border: 1px blue solid;
        // box-sizing: border-box;

        // display: inline-block;

        position: absolute;
        top: 3px;
        left: 300px;
        // height: 100%;

        // float: right;

        // display: inline-table;
        // flex-direction: row;
        // justify-content: space-between;
        // align-items: center;

        * {
            margin-left: 10px;
        }

        button {
            font-size: 12px;
            height: auto;
        }

        .operate {
            // border: 1px purple solid;
            // box-sizing: border-box;
            display: inline-block;


            float: right;

            // position: absolute;
            // right: 0;

            // transform: translateX(100%);

            // button {
            //     font-size: 12px;
            //     height: auto;
            // }
        }

        b {
            // border: 1px red solid;
            // box-sizing: border-box;
            display: inline-block;
            font-size: 12px;
        }

    }
</style>